import { createSlice } from '@reduxjs/toolkit';
import api from '../api';

const initialState = {
  mostraSettings: {},
};

const mostraSettings = createSlice({
  name: 'mostraSettings',
  initialState,
  reducers: {
    setMostraSettings(state, action) {
      state.mostraSettings = action.payload;
    },
    clearMostraSettings(state) {
      state.mostraSettings = {};
    },
  },
});

export const { setMostraSettings, clearMostraSettings } = mostraSettings.actions;

export const getMostraSettings = () => async (dispatch) => {
  try {
    const response = await api.get('/mostra-settings');

    const { data } = response;

    dispatch(setMostraSettings(data));

    return response;
  } catch (error) {
    console.log('error.response: ', error);
    return error.response;
  }
};

export const updateMostraSettings = (data) => async () => {
  try {
    const response = await api.post(`/mostra-settings/`, data);

    return response;
  } catch (error) {
    console.log('error.response: ', error.response);
    return error.response;
  }
};

export const sendMailToAllUsers = (data) => async () => {
  try {
    const response = await api.post('/mostra-settings/send-mail/all-users', data);
    console.log('response', response);
    return response;
  } catch (error) {
    console.log('error.response: ', error.response);
    return error.response;
  }
};

const mostraSettingsReducer = mostraSettings.reducer;

export default mostraSettingsReducer;
