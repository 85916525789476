import { createSlice } from '@reduxjs/toolkit';
// import { differenceInYears } from 'date-fns';
import api from '../api';

const initialState = {
  events: [],
  showFirstEventToSubscribeModal: false,
  showSecondEventToSubscribeModal: false,
  showThirdEventToSubscribeModal: false,
  showFourthEventToSubscribeModal: false,
};

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setEvents(state, action) {
      state.events = action.payload;
    },
    clearEvents(state) {
      state.events = [];
    },
    setShowFirstEventToSubscribeModal(state, action) {
      console.log('action', action);
      state.showFirstEventToSubscribeModal = action.payload;
    },
    setShowSecondEventToSubscribeModal(state, action) {
      state.showSecondEventToSubscribeModal = action.payload;
    },
    setShowThirdEventToSubscribeModal(state, action) {
      state.showThirdEventToSubscribeModal = action.payload;
    },
    setShowFourthEventToSubscribeModal(state, action) {
      state.showFourthEventToSubscribeModal = action.payload;
    },
  },
});

export const {
  setEvents,
  clearEvents,
  setShowFirstEventToSubscribeModal,
  setShowSecondEventToSubscribeModal,
  setShowThirdEventToSubscribeModal,
  setShowFourthEventToSubscribeModal,
} = eventSlice.actions;

export const getEvents = () => async (dispatch) => {
  try {
    const response = await api.get('/events');
    const { data: events } = response;

    dispatch(setEvents(events));

    return response;
  } catch (error) {
    console.log('error.response: ', error);
    return error.response;
  }
};

export const createEvent = (formData) => async () => {
  try {
    const response = await api.post('/events', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return response;
  } catch (error) {
    console.log('error.response: ', error.response);
    return error.response;
  }
};

export const updateEvent = (eventId, formData) => async () => {
  try {
    const response = await api.put(`/events/${eventId}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return response;
  } catch (error) {
    console.log('error.response: ', error.response);
    return error.response;
  }
};

export const removeEvent = (eventId) => async () => {
  console.log('removeEvent : ', eventId);
  try {
    const response = await api.delete(`/events/${eventId}`);
    return response;
  } catch (error) {
    console.log('error.response: ', error.response);
    return error.response;
  }
};

export const subscribeEvent = (scheduleId) => async () => {
  console.log('subscribe: ', scheduleId);
  try {
    const response = await api.get(`/events/subscribe/${scheduleId}`);
    return response;
  } catch (error) {
    console.log('error.response: ', error.response);
    return error.response;
  }
};

export const unSubscribeEvent = (scheduleId) => async () => {
  console.log('unSubscribe: ', scheduleId);
  try {
    const response = await api.get(`/events/unsubscribe/${scheduleId}`);
    return response;
  } catch (error) {
    console.log('error.response: ', error.response);
    return error.response;
  }
};

export const createSchedule = (data) => async () => {
  try {
    const response = await api.post('/events/schedules/', data);

    return response;
  } catch (error) {
    console.log('error.response: ', error.response);
    return error.response;
  }
};

export const updateSchedule = (scheduleId, data) => async () => {
  try {
    const response = await api.put(`/events/schedules/${scheduleId}`, data);

    return response;
  } catch (error) {
    console.log('error.response: ', error.response);
    return error.response;
  }
};

export const removeSchedule = (scheduleId) => async () => {
  try {
    const response = await api.delete(`/events/schedules/${scheduleId}`);

    return response;
  } catch (error) {
    console.log('error.response: ', error.response);
    return error.response;
  }
};

export const generatePdfEventsSchedules = (data) => async () => {
  try {
    const response = await api.post('/events/report/', data, { responseType: 'blob', timeout: 300000 });
    console.log('response');
    return response;
  } catch (error) {
    console.log('error.response: ', error.response);
    return error.response;
  }
};

export const generatePdfEventsubscribedsByMinicourse = (data) => async () => {
  try {
    const response = await api.post('/events/report/subscribeds-by-minicourse', data, { responseType: 'blob', timeout: 300000 });
    console.log('response');
    return response;
  } catch (error) {
    console.log('error.response: ', error.response);
    return error.response;
  }
};

export const generatePdfAllsubscribeds = (data) => async () => {
  try {
    const response = await api.post('/events/report/all-subscribeds', data, { responseType: 'blob', timeout: 300000 });
    console.log('response');
    return response;
  } catch (error) {
    console.log('error.response: ', error.response);
    return error.response;
  }
};

export const generatePdfConfirmedSubscription = () => async () => {
  try {
    const response = await api.post('/schedules/generate-pdf/user-subscriptions', {}, { responseType: 'blob', timeout: 300000 });

    return response;
  } catch (error) {
    console.log('error.response: ', error.response);
    return error.response;
  }
};

export const generatePdfBraceletUsers = () => async () => {
  try {
    const response = await api.post('/schedules/generate-pdf/bracelet-users', {}, { responseType: 'blob', timeout: 300000 });

    return response;
  } catch (error) {
    console.log('error.response: ', error.response);
    return error.response;
  }
};

export const generatePdfBraceletUsers2 = () => async () => {
  try {
    const response = await api.post('/schedules/generate-pdf/bracelet-users-2', {}, { responseType: 'blob', timeout: 300000 });

    return response;
  } catch (error) {
    console.log('error.response: ', error.response);
    return error.response;
  }
};

export const sendMailUserConfirmedSubscription = () => async () => {
  try {
    const response = await api.post('/schedules/send-mail/user-subscriptions');

    return response;
  } catch (error) {
    console.log('error.response: ', error.response);
    return error.response;
  }
};

const eventReducer = eventSlice.reducer;

export default eventReducer;
