import axios from 'axios';

import { SERVICE_URL } from './config';
import { setGlobalLoading } from './layout/layoutSlice';
import { store } from './store';

const instance = axios.create({
  baseURL: SERVICE_URL,
  timeout: 25000,
});

instance.defaults.headers['Content-Type'] = 'application/json';

instance.interceptors.request.use(
  async (config) => {
    // Loading to true
    store.dispatch(setGlobalLoading({ loading: true }));

    const state = store.getState();
    const isLogin = await state.auth.isLogin;

    if (isLogin) {
      config.headers.Authorization = `bearer ${await state.auth.currentUser.access_token}`;
    }

    return config;
  },
  async (error) => {
    // Loading to false
    store.dispatch(setGlobalLoading({ loading: false }));
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async (response) => {
    // Loading to false
    store.dispatch(setGlobalLoading({ loading: false }));

    return response;
  },
  async (error) => {
    // Loading to false
    store.dispatch(setGlobalLoading({ loading: false }));
    return Promise.reject(error);
  }
);

export default instance;
