// import redux and persist plugins
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import persistStore from 'reduxjs-toolkit-persist/es/persistStore';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'reduxjs-toolkit-persist/es/constants';
import { createStateSyncMiddleware, withReduxStateSync } from 'redux-state-sync';

// import theme reducers
import settingsReducer from 'settings/settingsSlice';
import layoutReducer from 'layout/layoutSlice';
import langReducer from 'lang/langSlice';
import authReducer from 'auth/authSlice';
import menuReducer from 'layout/nav/main-menu/menuSlice';
import notificationReducer from 'layout/nav/notifications/notificationSlice';
import scrollspyReducer from 'components/scrollspy/scrollspySlice';

// import app reducers
import calendarReducer from 'views/apps/calendar/calendarSlice';
import contactsReducer from 'views/apps/contacts/contactsSlice';
import chatReducer from 'views/apps/chat/chatSlice';
import mailboxReducer from 'views/apps/mailbox/mailboxSlice';
import tasksReducer from 'views/apps/tasks/tasksSlice';

// import reducers
import userReducer from 'redux/userSlice';
import eventReducer from 'redux/eventSlice';
import mostraSettingsReducer from 'redux/mostraSettingsSlice';

// import persist key
import { REDUX_PERSIST_KEY } from 'config.js';

// Old way to sync state between tabs, now we're using the 'react-state-sync' library
// import crossBrowserListener from 'utils/redux-persist-listener';

const persistConfig = {
  key: REDUX_PERSIST_KEY,
  storage,
  whitelist: ['auth', 'menu', 'settings', 'lang', 'user', 'event', 'mostraSettings'],
};

const reducers = combineReducers({
  settings: settingsReducer,
  layout: layoutReducer,
  lang: langReducer,
  auth: authReducer,
  menu: menuReducer,
  notification: notificationReducer,
  scrollspy: scrollspyReducer,
  calendar: calendarReducer,
  contacts: contactsReducer,
  chat: chatReducer,
  mailbox: mailboxReducer,
  tasks: tasksReducer,
  user: userReducer,
  event: eventReducer,
  mostraSettings: mostraSettingsReducer,
});
const rootReducer = withReduxStateSync(reducers);

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: { warnAfter: 256 },
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        warnAfter: 128,
      },
    }).concat(
      createStateSyncMiddleware({
        broadcastChannelOption: { type: 'localstorage', name: 'root' },
      })
    );
  },
});

/*
 *Old way we got this
 */
// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       immutableCheck: { warnAfter: 256 },
//       serializableCheck: {
//         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//         warnAfter: 128,
//       },
//     }),
// });

// window.addEventListener('storage', crossBrowserListener(store, persistConfig));

const persistedStore = persistStore(store);
export { store, persistedStore };
