/* eslint-disable */
import { lazy } from 'react';
import { USER_ROLE } from 'constants.js';
import { DEFAULT_PATHS } from 'config.js';

const homepage = lazy(() => import('views/pages/home/Home'));
const adminDashboard = lazy(() => import('views/pages/admin/AdminDashboard'));

const pages = {
  index: lazy(() => import('views/pages/Pages')),
  authentication: {
    index: lazy(() => import('views/pages/authentication/Authentication')),
    login: lazy(() => import('views/pages/authentication/Login')),
    register: lazy(() => import('views/pages/authentication/Register')),
    forgotPassword: lazy(() => import('views/pages/authentication/ForgotPassword')),
    resetPassword: lazy(() => import('views/pages/authentication/ResetPassword')),
  },
  profile: {
    index: lazy(() => import('views/pages/profile/Profile')),
    standard: lazy(() => import('views/pages/profile/ProfileStandard')),
    settings: lazy(() => import('views/pages/profile/ProfileSettings')),
  },
  events: lazy(() => import('views/pages/admin/events/Events')),
  users: lazy(() => import('views/pages/admin/users/Users')),
  subscriptions: lazy(() => import('views/pages/subscriptions/Subscriptions')),
};

const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;

const routesAndMenuItems = {
  mainMenuItems: [
    {
      path: DEFAULT_PATHS.APP,
      exact: true,
      redirect: true,
      to: `${appRoot}/home`,
    },
    {
      path: `${appRoot}/home`,
      label: 'menu.home',
      icon: 'home',
      component: homepage,
      roles: [USER_ROLE.Admin, USER_ROLE.User],
    },
    // {
    //   path: `${appRoot}/pages/subscriptions`,
    //   label: 'menu.subscriptions',
    //   icon: 'diploma',
    //   component: pages.subscriptions,
    //   roles: [USER_ROLE.Admin],
    // },
    {
      path: `${appRoot}/admin`,
      label: 'menu.admin',
      icon: 'shield',
      component: adminDashboard,
      roles: [USER_ROLE.Admin],
    },
    {
      path: `${appRoot}/pages/users`,
      label: 'menu.users',
      icon: 'user',
      component: pages.users,
      roles: [USER_ROLE.Admin],
    },
    {
      path: `${appRoot}/pages/events`,
      label: 'menu.events',
      icon: 'diploma',
      component: pages.events,
      roles: [USER_ROLE.Admin],
    },
    {
      path: `${appRoot}/pages`,
      label: 'menu.pages',
      icon: 'notebook-1',
      component: pages.index,
      showInMenu: false,
      subs: [
        {
          path: '/authentication',
          label: 'menu.authentication',
          component: pages.authentication.index,
          subs: [
            { path: '/login', label: 'menu.login', component: pages.authentication.login, noLayout: true },
            { path: '/register', label: 'menu.register', component: pages.authentication.register, noLayout: true },
            { path: '/forgot-password', label: 'menu.forgot-password', component: pages.authentication.forgotPassword, noLayout: true },
            { path: '/reset-password', label: 'menu.reset-password', component: pages.authentication.resetPassword, noLayout: true },
          ],
        },
        {
          path: '/profile',
          label: 'menu.profile',
          component: pages.profile.index,
          subs: [
            { path: '/standard', label: 'menu.standard', component: pages.profile.standard },
            { path: '/settings', label: 'menu.settings', component: pages.profile.settings },
          ],
        },
      ],
    },
  ],
  sidebarItems: [
    { path: '#connections', label: 'menu.connections', icon: 'diagram-1', hideInRoute: true },
    { path: '#bookmarks', label: 'menu.bookmarks', icon: 'bookmark', hideInRoute: true },
    { path: '#requests', label: 'menu.requests', icon: 'diagram-2', hideInRoute: true },
    {
      path: '#account',
      label: 'menu.account',
      icon: 'user',
      hideInRoute: true,
      subs: [
        { path: '/settings', label: 'menu.settings', icon: 'gear', hideInRoute: true },
        { path: '/password', label: 'menu.password', icon: 'lock-off', hideInRoute: true },
        { path: '/devices', label: 'menu.devices', icon: 'mobile', hideInRoute: true },
      ],
    },
    {
      path: '#notifications',
      label: 'menu.notifications',
      icon: 'notification',
      hideInRoute: true,
      subs: [
        { path: '/email', label: 'menu.email', icon: 'email', hideInRoute: true },
        { path: '/sms', label: 'menu.sms', icon: 'message', hideInRoute: true },
      ],
    },
    {
      path: '#downloads',
      label: 'menu.downloads',
      icon: 'download',
      hideInRoute: true,
      subs: [
        { path: '/documents', label: 'menu.documents', icon: 'file-text', hideInRoute: true },
        { path: '/images', label: 'menu.images', icon: 'file-image', hideInRoute: true },
        { path: '/videos', label: 'menu.videos', icon: 'file-video', hideInRoute: true },
      ],
    },
  ],
};
export default routesAndMenuItems;
