import { createSlice } from '@reduxjs/toolkit';
import { differenceInYears } from 'date-fns';
import api from '../api';

const initialState = {
  users: [],
  subscriptions: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUsers(state, action) {
      state.users = action.payload;
    },
    setSubscriptions(state, action) {
      state.subscriptions = action.payload;
    },
    clearUser(state) {
      state.users = [];
    },
  },
});

export const { setUsers, clearUser, setSubscriptions } = userSlice.actions;

export const getUserSubscriptions = () => async (dispatch) => {
  try {
    const response = await api.get('/schedules/user-subscriptions');
    const { data: subscriptions } = response;

    dispatch(setSubscriptions(subscriptions));

    return response;
  } catch (error) {
    console.log('error.response: ', error);
    return error.response;
  }
};

export const getUsers = () => async (dispatch) => {
  try {
    const response = await api.get('users');
    const { data: users } = response;

    const updatedUsers = users.map((each) => {
      return {
        ...each,
        name: each.fullname,
        thumb: each.avatar,
        avatar: each.avatar,
        birth_date: each.birth_date,
        age: differenceInYears(new Date(), new Date(each.birth_date)),
      };
    });

    // console.log('updatedUsers', updatedUsers);

    dispatch(setUsers(updatedUsers));

    return response;
  } catch (error) {
    console.log('error.response: ', error);
    return error.response;
  }
};

export const updateUserByAdmin = (id, formData) => async () => {
  try {
    const response = await api.put(`/users/update/by-admin/${id}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return response;
  } catch (error) {
    console.log('error.response: ', error.response);
    return error.response;
  }
};

export const updateUserByUser = (formData) => async () => {
  try {
    const response = await api.put(`/users/update/by-user/`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return response;
  } catch (error) {
    console.log('error.response: ', error.response);
    return error.response;
  }
};

export const updateUserSettings = (formData) => async () => {
  try {
    const response = await api.put(`/users/update-user-settings`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return response;
  } catch (error) {
    console.log('error.response: ', error.response);
    return error.response;
  }
};

export const deleteUser = (id) => async () => {
  try {
    const response = await api.delete(`/users/${id}`);

    return response;
  } catch (error) {
    console.log('error.response: ', error.response);
    return error.response;
  }
};

const userReducer = userSlice.reducer;

export default userReducer;
