import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showingNavMenu: '',
  loading: false,
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    layoutShowingNavMenu(state, action) {
      state.showingNavMenu = action.payload;
    },
    setGlobalLoading(state, action) {
      state.loading = action.payload;
    },
  },
});
export const { layoutShowingNavMenu } = layoutSlice.actions;
export const { setGlobalLoading } = layoutSlice.actions;

const layoutReducer = layoutSlice.reducer;

export default layoutReducer;
